body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  :root {
    --theme-color: #990000;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.rcw-message {
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rcw-conversation-container>.rcw-header {
    background-color:  var(--theme-color);
}

.rcw-message-text p {
    color:black !important;
    font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
}

.rcw-launcher {
    background-color:  var(--theme-color);
}
.rcw-client .rcw-message-text { 
    background-color:  #f5f5f5;
    color:#070707;
}
.rcw-input {
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
}
.rcw-client .rcw-message-text{
    padding: 10px;
}
.rcw-conversation-container .rcw-title {
    font-size: 20px;
    padding: 5px 0;
    color: #FFFFFF;
}

.rcw-conversation-container .rcw-title span {
    font-size: 14px;
}

.rcw-conversation-resizer {
    display: none !important;
}

.rcw-conversation-container {
    max-width: 320px !important;
}

.rcw-conversation-container .rcw-top-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rcw-conversation-container .rcw-close-button {
    display: none !important;
}

.rcw-conversation-container .rcw-bubble-chat-icon {
    background: var(--theme-color);
    border: 0 !important;
    margin-left: 5px;
    width: 24px;
    height: 24px;
}
.rcw-conversation-container .rcw-minimize-button {
    background: var(--theme-color);
    border: 0 !important;
}

.rcw-conversation-container .rcw-terminate-button {
    background:  var(--theme-color);
    border: 0 !important;
    margin-right: 5px;
}

.quick-button {
    border: 2px solid #333333;
    color: #000000 !important;
}
.quick-button:active {
    background: #333333;
}

.rcw-minimize-button img {
    width: 16px;
    height: 16px;
}

.rcw-terminate-button img {
    width: 16px;
    height: 16px;
}
.connection-error {
    display: flex;
}
.connection-error img {
    width: 32px;
    height: 32px;
}
.connection-error p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:0;
    padding-left: 5px;
    padding-top: 5px;
}

.quick-buttons-container .quick-buttons {
    display: flex;
    flex-direction: column;
}

.quick-buttons-container .quick-buttons .quick-list-button {
    margin-top: 10px;
}
.app-disabled {
    pointer-events: none; 
    opacity: 0.5; 
   
}
.rcw-picker-btn {
    display: none !important;
}
.rcw-new-message {
    width: calc(100% - 50px) !important;
}

@media screen and (max-width: 800px) {
    .App {
        height: 100vh;
    }

    .rcw-conversation-container {
        max-width: 100vw !important;
    }

    .rcw-widget-container {
        height: 100%;
    }

    .rcw-conversation-container .rcw-close-button {
        background-color:  var(--theme-color);
    }

    .rcw-hide-sm {
        display: none !important;
    }
}